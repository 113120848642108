<template>
    <div class="flex-1 p-8">
        <div class="panel flex flex-col">
            <page-header icon="app-page-account-stroke" :label="`Manage Plan > ${title}`">
                <a :href="route('admin.plans.index')" class="button-icon button-sm">
                    <app-icon name="close"></app-icon>
                </a>
            </page-header>

            <div class="flex">
                <div class="w-4/5">
                    <div class="flex mb-4">
                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Plan Name" :error="form.errors.get('name')">
                                <input
                                    v-model="form.name"
                                    type="text"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Internal Name" :error="form.errors.get('internal_name')">
                                <input
                                    v-model="form.internal_name"
                                    type="text"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Stripe API ID (optional)" :error="form.errors.get('stripe_id')">
                                <input
                                    v-model="form.stripe_id"
                                    type="text"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Linked Plan (optional)" :error="form.errors.get('linked_plan_id')">
                                <simple-picker
                                    v-model="form.linked_plan_id"
                                    :items="allPlans"
                                    item-label="internal_name"
                                    item-value="id"
                                ></simple-picker>
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Trial Days (optional)" :error="form.errors.get('trial_days')">
                                <input
                                    v-model="form.trial_days"
                                    type="number"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>
                    </div>

                    <div class="mb-4 mr-8">
                        <form-field-wrapper label="Description" :error="form.errors.get('description')">
                            <input
                                v-model="form.description"
                                type="text"
                                class="form-field"
                            >
                        </form-field-wrapper>
                    </div>

                    <div class="flex">
                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Line 1" :error="form.errors.get('billing_info_line_1')">
                                <input
                                    v-model="form.billing_info_line_1"
                                    type="text"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Line 2" :error="form.errors.get('billing_info_line_2')">
                                <input
                                    v-model="form.billing_info_line_2"
                                    type="text"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>

                        <div class="flex-1 mr-8">
                            <form-field-wrapper label="Line 3" :error="form.errors.get('billing_info_line_3')">
                                <input
                                    v-model="form.billing_info_line_3"
                                    type="text"
                                    class="form-field"
                                >
                            </form-field-wrapper>
                        </div>
                    </div>
                </div>

                <div class="w-1/5">
                    <form-field-wrapper label="Public Plan?" :error="form.errors.get('public')">
                        <select-list v-model="form.public">
                            <select-option :id="false" class="mb-2">No, Enterprise or custom plan</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>

                    <form-field-wrapper label="Overrides Ticketing" :error="form.errors.get('overrides_ticketing')">
                        <select-list v-model="form.overrides_ticketing">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <form-field-wrapper
                label="Plan Details HTML"
                class="mt-8"
                :error="form.errors.get('details_html')"
            >
                <textarea v-model="form.details_html" class="form-field"></textarea>
            </form-field-wrapper>

            <div class="flex justify-end mt-8">
                <button class="flex items-center w-auto" @click="toggleAllAlerts">
                    <app-icon
                        :name="allAlertsValue ? 'alarm-bell-off' : 'alarm-bell'"
                        class="h-5 w-5 mr-2"
                        :class="allAlertsValue ? 'text-purple' : 'text-gray-400'"
                        stroke
                    ></app-icon>

                    <span>Toggle All Notifications</span>
                </button>
            </div>

            <div class="flex mt-4">
                <div class="w-1/4">
                    <form-field-wrapper
                        label="Billing Period"
                        :error="form.errors.getAny(['billing_period', 'price'])"
                        class="mb-2"
                    >
                        <select-list
                            v-model="form.billing_period"
                            class="space-y-2"
                        >
                            <select-option id="free" disabled>N/A; FREE Plan</select-option>

                            <select-option id="monthly" :disabled="form.billing_period === 'free'">
                                <template #default="{ selected }">
                                    <div v-if="selected" class="flex items-center">
                                        Monthly: $
                                        <input
                                            v-model.number="adjustedPrice"
                                            type="number"
                                            class="form-field w-24 ml-2"
                                            min="0"
                                            step="0.01"
                                        >
                                    </div>

                                    <template v-else>Monthly</template>
                                </template>
                            </select-option>

                            <select-option id="annual" :disabled="form.billing_period === 'free'">
                                <template #default="{ selected }">
                                    <div v-if="selected" class="flex items-center">
                                        Yearly: $
                                        <input
                                            v-model.number="adjustedPrice"
                                            type="number"
                                            class="form-field w-24 ml-2"
                                        >
                                    </div>

                                    <template v-else>Yearly</template>
                                </template>
                            </select-option>

                            <select-option id="one-time" :disabled="form.billing_period === 'free'">
                                <template #default="{ selected }">
                                    <div v-if="selected" class="flex items-center">
                                        One-time: $
                                        <input
                                            v-model.number="adjustedPrice"
                                            type="number"
                                            class="form-field w-24 ml-2"
                                        >
                                    </div>

                                    <template v-else>One-time</template>
                                </template>
                            </select-option>

                            <select-option id="custom" :disabled="form.billing_period === 'free'">
                                <template #default="{ selected }">
                                    <div v-if="selected" class="flex items-center">
                                        <span>Custom:</span>

                                        <input
                                            v-model="form.price"
                                            class="form-field w-24 ml-2"
                                            type="text"
                                        >
                                    </div>

                                    <template v-else>Custom</template>
                                </template>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.EventLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.EventLimit.notifyImmediately">Events Limit</feature-label>
                        </template>

                        <select-list v-model="form.features.EventLimit.unlimited">
                            <select-option :id="true" class="mb-2">Unlimited</select-option>

                            <select-option :id="false">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.EventLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.get('features.RsvpLimit.type') || form.errors.get('features.RsvpLimit.max')">
                        <template #label>
                            <feature-label v-model="form.features.RsvpLimit.notifyImmediately">RSVP Limit</feature-label>
                        </template>

                        <select-list v-model="form.features.RsvpLimit.type">
                            <select-option id="fixed" class="mb-2">
                                <template #default="{ selected }">
                                    <div v-if="selected" class="flex items-center">
                                        Per event:
                                        <input
                                            v-model="form.features.RsvpLimit.max"
                                            type="number"
                                            class="form-field w-24 ml-2"
                                        >
                                    </div>

                                    <template v-else>Per event</template>
                                </template>
                            </select-option>

                            <select-option id="monthly">
                                <template #default="{ selected }">
                                    <div v-if="selected" class="flex items-center">
                                        Per month:
                                        <input
                                            v-model="form.features.RsvpLimit.max"
                                            type="number"
                                            class="form-field w-24 ml-2"
                                        >
                                    </div>

                                    <template v-else>Per month</template>
                                </template>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>

                    <form-field-wrapper label="Deleted RSVPs" :error="form.errors.get('features.RsvpLimit.includeDeleted')">
                        <select-list v-model="form.features.RsvpLimit.includeDeleted" class="space-y-2">
                            <select-option :id="true">Counted</select-option>
                            <select-option :id="false">NOT Counted</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.InviteeLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.InviteeLimit.notifyImmediately">Invitees Per Event</feature-label>
                        </template>

                        <select-list v-model="form.features.InviteeLimit.unlimited">
                            <select-option :id="true" class="mb-2">Unlimited</select-option>

                            <select-option :id="false">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.InviteeLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.EmailCredits.*')">
                        <template #label>
                            <feature-label v-model="form.features.EmailCredits.notifyImmediately">Email Credits</feature-label>
                        </template>

                        <div class="flex items-center">
                            Per month:
                            <input
                                v-model="form.features.EmailCredits.allowance"
                                type="number"
                                class="form-field w-24 ml-2"
                            >
                        </div>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.SharedUserLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.SharedUserLimit.notifyImmediately">Co-Managers</feature-label>
                        </template>

                        <select-list v-model="form.features.SharedUserLimit.unlimited">
                            <select-option :id="true" class="mb-2">Unlimited</select-option>

                            <select-option :id="false">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.SharedUserLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.SecondaryEventLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.SecondaryEventLimit.notifyImmediately">Secondary Events</feature-label>
                        </template>

                        <select-list v-model="form.features.SecondaryEventLimit.unlimited">
                            <select-option :id="true" class="mb-2">Unlimited</select-option>

                            <select-option :id="false">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.SecondaryEventLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CalendarInvitations.*')">
                        <template #label>
                            <feature-label v-model="form.features.CalendarInvitations.notifyImmediately">Calendar Invitations</feature-label>
                        </template>

                        <select-list v-model="form.features.CalendarInvitations.type">
                            <select-option id="basic" class="mb-2">Basic</select-option>
                            <select-option id="advanced">Advanced</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.LanguageLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.LanguageLimit.notifyImmediately">Multiple Languages</feature-label>
                        </template>

                        <select-list v-model="form.features.LanguageLimit.type">
                            <select-option id="single">One primary only</select-option>

                            <select-option id="multiple">
                                <div class="flex items-center">
                                    Up to X additional:
                                    <input
                                        v-model="form.features.LanguageLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>

                            <select-option id="unlimited">Unlimited</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.Tagging.*')">
                        <template #label>
                            <feature-label v-model="form.features.Tagging.notifyImmediately">Tagging</feature-label>
                        </template>

                        <select-list v-model="form.features.Tagging.type">
                            <select-option id="disabled" class="mb-2">No</select-option>

                            <select-option id="limited">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.Tagging.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>

                            <select-option id="unlimited">Unlimited</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper label="Available Templates" :error="form.errors.get('event_types')">
                        <select-list v-model="form.is_universal">
                            <select-option :id="true" class="mb-2">All</select-option>
                            <select-option :id="false">Limit to:</select-option>
                        </select-list>

                        <select-list
                            v-model="form.event_types"
                            class="flex flex-wrap"
                            multi-select
                        >
                            <select-option
                                v-for="{ id, name } in allEventTypes"
                                :id="id"
                                :key="id"
                                class="w-1/2 mt-2"
                            >
                                {{ name }}
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.EventDuplication.*')">
                        <template #label>
                            <feature-label v-model="form.features.EventDuplication.notifyImmediately">Duplicate Events</feature-label>
                        </template>

                        <select-list v-model="form.features.EventDuplication.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CustomCss.*')">
                        <template #label>
                            <feature-label v-model="form.features.CustomCss.notifyImmediately">Custom CSS</feature-label>
                        </template>

                        <select-list v-model="form.features.CustomCss.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.PrimaryProductLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.PrimaryProductLimit.notifyImmediately">Tiers &#47; Sessions</feature-label>
                        </template>

                        <select-list v-model="form.features.PrimaryProductLimit.type">
                            <select-option id="disabled" class="mb-2">No</select-option>

                            <select-option id="limited">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.PrimaryProductLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>

                            <select-option id="unlimited">Unlimited</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.MealBlockLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.MealBlockLimit.notifyImmediately">Meal Preferences</feature-label>
                        </template>

                        <select-list v-model="form.features.MealBlockLimit.type">
                            <select-option id="disabled" class="mb-2">No</select-option>

                            <select-option id="limited">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.MealBlockLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>

                            <select-option id="unlimited">Unlimited</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CustomLogo.*')">
                        <template #label>
                            <feature-label v-model="form.features.CustomLogo.notifyImmediately">Upload Logo</feature-label>
                        </template>

                        <select-list v-model="form.features.CustomLogo.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.NoteBlock.*')">
                        <template #label>
                            <feature-label v-model="form.features.NoteBlock.notifyImmediately">Note from Guest</feature-label>
                        </template>

                        <select-list v-model="form.features.NoteBlock.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.LimitedCapacity.*')">
                        <template #label>
                            <feature-label v-model="form.features.LimitedCapacity.notifyImmediately">Limited Number Availability</feature-label>
                        </template>

                        <select-list v-model="form.features.LimitedCapacity.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.TermsAndConditionsQuestion.*')">
                        <template #label>
                            <feature-label v-model="form.features.TermsAndConditionsQuestion.notifyImmediately">Waiver Question-Type</feature-label>
                        </template>

                        <select-list v-model="form.features.TermsAndConditionsQuestion.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.AskQuestionByTag.*')">
                        <template #label>
                            <feature-label v-model="form.features.AskQuestionByTag.notifyImmediately">Ask Question by Tag</feature-label>
                        </template>

                        <select-list v-model="form.features.AskQuestionByTag.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper label="Can Send Emails To" :error="form.errors.getByPath('features.CampaignAudience.*')">
                        <select-list v-model="form.features.CampaignAudience.types" multi-select>
                            <select-option id="all-invitees" class="mb-2">All Invitees</select-option>
                            <select-option id="invitees-without-reply" class="mb-2">Invitees who haven't replied</select-option>
                            <select-option id="attending" class="mb-2">Attending</select-option>
                            <select-option id="maybe-attending" class="mb-2">Maybe attending</select-option>
                            <select-option id="not-attending" class="mb-2">Not attending</select-option>
                            <select-option id="all-respondents" class="mb-2">All respondents</select-option>
                            <select-option id="custom" class="mb-2">Select individual recipients</select-option>
                            <select-option id="predicates">Custom audiences</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper label="Invite List" :error="form.errors.getByPath('features.InviteList.*')">
                        <select-list v-model="form.features.InviteList.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.PasscodeProtection.*')">
                        <template #label>
                            <feature-label v-model="form.features.PasscodeProtection.notifyImmediately">Password Protection</feature-label>
                        </template>

                        <select-list v-model="form.features.PasscodeProtection.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper label="Guests" :error="form.errors.getByPath('features.AdditionalGuests.*')">
                        <select-list v-model="form.features.AdditionalGuests.types" multi-select>
                            <select-option id="unlimited" class="mb-2">Unlimited</select-option>
                            <select-option id="single" class="mb-2">+1</select-option>
                            <select-option id="multiple">+X</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.InviteeNameEditing.*')">
                        <template #label>
                            <feature-label v-model="form.features.InviteeNameEditing.notifyImmediately">Prevent Invitee Name Edits</feature-label>
                        </template>

                        <select-list v-model="form.features.InviteeNameEditing.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.Publishing.*')" label="Publishing">
                        <select-list v-model="form.features.Publishing.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.ConfirmationEmailBranding.*')" label="Confirmation Email Branding">
                        <select-list v-model="form.features.ConfirmationEmailBranding.type">
                            <select-option id="rsvpify" class="mb-2">RSVPify branded</select-option>
                            <select-option id="cobranded" class="mb-2">Co-branded</select-option>
                            <select-option id="whitelabel">White label</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CampaignEmailBranding.*')" label="Email Campaign Branding">
                        <select-list v-model="form.features.CampaignEmailBranding.type">
                            <select-option id="rsvpify" class="mb-2">RSVPify branded</select-option>
                            <select-option id="cobranded" class="mb-2">Co-branded</select-option>
                            <select-option id="whitelabel">White label</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.FormEmbedding.*')" label="Form Embedding">
                        <select-list v-model="form.features.FormEmbedding.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.RecurringTimeSlots.*')">
                        <template #label>
                            <feature-label v-model="form.features.RecurringTimeSlots.notifyImmediately">Recurring Time Slots</feature-label>
                        </template>

                        <select-list v-model="form.features.RecurringTimeSlots.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.SeatingChartLimit.*')" label="Seating Chart">
                        <select-list v-model="form.features.SeatingChartLimit.type">
                            <select-option id="single">Try Only (1 chart, 10 assignments)</select-option>

                            <select-option id="multiple">
                                <div class="flex items-center">
                                    Number of charts:
                                    <input
                                        v-model="form.features.SeatingChartLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>

                            <select-option id="unlimited">Unlimited charts</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.QrCode.*')" label="QR Code">
                        <select-list v-model="form.features.QrCode.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CustomDataFieldLimit.*')" label="Custom Data Fields Limit">
                        <select-list v-model="form.features.CustomDataFieldLimit.hidden">
                            <select-option :id="true" class="mb-2">Hidden</select-option>

                            <select-option :id="false">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.CustomDataFieldLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.BrandedFooter.*')" label="Show Branded Footer">
                        <select-list v-model="form.features.BrandedFooter.enabled">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.BrandedConfirmationScreen.*')" label="Show Branded Confirmation Screen">
                        <select-list v-model="form.features.BrandedConfirmationScreen.enabled">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.BrandedRegistrationExperience.*')" label="Show Branded Registration Experience">
                        <select-list v-model="form.features.BrandedRegistrationExperience.enabled">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.BrandedEmailFooter.*')" label="Show Branded Email Footer">
                        <select-list v-model="form.features.BrandedEmailFooter.enabled">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.InviteePasswords.*')" label="Invitee Passwords">
                        <select-list v-model="form.features.InviteePasswords.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.SubmissionEditMfa.*')" label="Submission Edit MFA">
                        <select-list v-model="form.features.SubmissionEditMfa.type">
                            <select-option id="hidden">Hide</select-option>
                            <select-option id="disabled" class="my-2">No</select-option>
                            <select-option id="enabled">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CustomQuestionLimit.*')">
                        <template #label>
                            <feature-label v-model="form.features.CustomQuestionLimit.notifyImmediately">Custom Question Limit</feature-label>
                        </template>

                        <select-list v-model="form.features.CustomQuestionLimit.unlimited">
                            <select-option :id="true" class="mb-2">Unlimited</select-option>

                            <select-option :id="false">
                                <div class="flex items-center">
                                    Limit to:
                                    <input
                                        v-model="form.features.CustomQuestionLimit.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                </div>
                            </select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.ClassyIntegration.*')" label="Classy Integration">
                        <select-list v-model="form.features.ClassyIntegration.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.PublicApi.*')" label="Public API">
                        <select-list v-model="form.features.PublicApi.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CheckInKiosk.*')" label="Check In: Kiosk">
                        <select-list v-model="form.features.CheckInKiosk.allowed">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CheckIn.*')">
                        <template #label>
                            <feature-label v-model="form.features.CheckIn.notifyImmediately">Check In</feature-label>
                        </template>

                        <select-list v-model="form.features.CheckIn.type">
                            <select-option id="hide">Hide</select-option>

                            <select-option id="try-free">
                                <div class="flex items-center">
                                    Try free:
                                    <input
                                        v-model="form.features.CheckIn.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                    actions
                                </div>
                            </select-option>

                            <select-option id="limited">
                                <div class="flex items-center">
                                    Limited:
                                    <input
                                        v-model="form.features.CheckIn.max"
                                        type="number"
                                        class="form-field w-24 ml-2"
                                    >
                                    actions
                                </div>
                            </select-option>

                            <select-option id="unlimited">Unlimited actions</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.Sso.*')" label="Single Sign-On">
                        <select-list v-model="form.features.Sso.type">
                            <select-option id="hidden">Hide</select-option>
                            <select-option id="disabled" class="my-2">No</select-option>
                            <select-option id="enabled">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CustomScripts.*')" label="Custom Scripts">
                        <select-list v-model="form.features.CustomScripts.type">
                            <select-option id="hidden">Hide</select-option>
                            <select-option id="disabled" class="my-2">No</select-option>
                            <select-option id="enabled">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CustomFonts.*')" label="Custom Fonts">
                        <select-list v-model="form.features.CustomFonts.type">
                            <select-option id="hidden">Hide</select-option>
                            <select-option id="disabled" class="my-2">No</select-option>
                            <select-option id="enabled">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.BrandedCalendarTitles.*')" label="Show Branded Calendar Titles">
                        <select-list v-model="form.features.BrandedCalendarTitles.enabled">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.CustomFromName.*')" label="Show Custom From Name">
                        <select-list v-model="form.features.CustomFromName.type">
                            <select-option id="hidden" class="mb-2">Hide</select-option>
                            <select-option id="disabled" class="mb-2">No</select-option>
                            <select-option id="enabled">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper
                        :error="form.errors.getByPath('features.OEmbedPoweredBy.*')"
                        label="oEmbed Powered by footer"
                        tooltip="Wordpress Powered by Footer."
                    >
                        <select-list v-model="form.features.OEmbedPoweredBy.enabled">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper
                        :error="form.errors.getByPath('features.SendingCampaignWithoutPaymentProcessorConnected.*')"
                        label="Sending emails without payment processor"
                        tooltip="Hours that need to pass before user can send emails without connected payment processor and number of non-preview emails that user can send without connecting payment processor."
                    >
                        <select-list v-model="form.features.SendingCampaignWithoutPaymentProcessorConnected.enabled">
                            <select-option :id="true">Allow</select-option>
                            <select-option :id="false" class="mt-2">Deny</select-option>
                        </select-list>

                        <div v-if="!form.features.SendingCampaignWithoutPaymentProcessorConnected.enabled" class="flex items-center">
                            <span
                                v-tippy
                                content="Number of hours that need to pass before user can send emails without connected payment processor."
                                class="tooltip-text"
                            >
                                Number of hours:
                            </span>
                            <input
                                v-model="form.features.SendingCampaignWithoutPaymentProcessorConnected.minimumHoursAfterSignup"
                                class="form-field w-24 ml-2"
                            >
                        </div>

                        <div v-if="!form.features.SendingCampaignWithoutPaymentProcessorConnected.enabled" class="flex items-center">
                            <span
                                v-tippy
                                content="Number of non-preview emails that user can send without connecting payment processor."
                                class="tooltip-text"
                            >
                                Number of non-preview emails:
                            </span>
                            <input
                                v-model="form.features.SendingCampaignWithoutPaymentProcessorConnected.numberOfNonPreviewCampaignsAvailable"
                                class="form-field w-24 ml-2"
                            >
                        </div>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.BotProtection.*')" label="Bot Protection">
                        <select-list v-model="form.features.BotProtection.type">
                            <select-option id="hidden">Hide</select-option>
                            <select-option id="disabled" class="my-2">No</select-option>
                            <select-option id="enabled">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex mt-8">
                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.RecaptchaEmailPreviews.*')" label="Recaptcha Email Previews">
                        <select-list v-model="form.features.RecaptchaEmailPreviews.enabled">
                            <select-option :id="false" class="mb-2">No</select-option>
                            <select-option :id="true">Yes</select-option>
                        </select-list>
                    </form-field-wrapper>
                </div>

                <div class="w-1/4">
                    <form-field-wrapper :error="form.errors.getByPath('features.EmailPreviewThrottle.*')" label="Email Preview Throttle">
                        <div class="flex items-center">
                            Limit to:
                            <input
                                v-model="form.features.EmailPreviewThrottle.max"
                                type="number"
                                class="form-field w-24 ml-2"
                            >
                        </div>
                    </form-field-wrapper>
                </div>
            </div>

            <div class="flex flex-row-reverse mt-8">
                <stateful-button
                    class="button-primary"
                    :loading="form.processing"
                    :disabled="form.billing_period === 'free'"
                    @click="save"
                >Save</stateful-button>

                <a class="button mr-2" :href="route('admin.plans.index')">Cancel</a>
            </div>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import Form from '@/validation/Form';

export default {
    name: 'PlanEditor',

    props: {
        allEventTypes: {
            type: Array,
            required: true
        },
        allPlans: {
            type: Array,
            required: true
        },
        defaultFeatures: {
            type: Object,
            default: () => { return {}; }
        },
        initialPlan: {
            type: Object,
            default: null
        }
    },

    data () {
        return {
            allEventTypesSelected: true,
            allAlertsValue: false,
            form: new Form({
                name: '',
                internal_name: '',
                public: false,
                is_universal: true,
                linked_plan_id: null,
                billing_period: 'custom',
                price: null,
                trial_days: null,
                stripe_id: '',
                description: '',
                billing_info_line_1: '',
                billing_info_line_2: '',
                billing_info_line_3: '',
                overrides_ticketing: false,
                details_html: '',
                event_types: this.allEventTypes.map(({ id }) => {
                    return id;
                }),
                features: cloneDeep(this.defaultFeatures)
            })
        };
    },

    computed: {
        adjustedPrice: {
            get () {
                return (this.form.price / 100) / this.billingPeriodInMonths;
            },
            set (newPrice) {
                this.form.price = newPrice * 100 * this.billingPeriodInMonths;
            }
        },

        isAnnual () {
            return this.form.billing_period === 'annual';
        },

        billingPeriodInMonths () {
            return this.isAnnual ? 12 : 1;
        },

        title () {
            return this.initialPlan ? this.initialPlan.name : 'Create a Plan';
        }
    },

    watch: {
        'form.billing_period': function (newValue) {
            if (newValue === 'free') {
                this.form.price = null;
            } else {
                this.form.price = this.form.price || 0;
            }
        },

        'form.is_universal': function (newValue) {
            if (!newValue) {
                return;
            }

            this.form.event_types = this.allEventTypes.map(({ id }) => {
                return id;
            });
        },

        'form.event_types': function (newValue) {
            if (newValue.length < this.allEventTypes.length) {
                this.form.is_universal = false;
            }
        }
    },

    created () {
        if (!this.initialPlan) {
            return;
        }

        this.loadPlan(this.initialPlan);
    },

    methods: {
        loadPlan (plan) {
            Object.assign(this.form, {
                ...pick(plan, this.form.fields()),
                event_types: plan.event_types.map(({ id }) => { return id; })
            });
        },

        newSaveRequest () {
            return this.initialPlan
                ? this.form.put(this.route('api.admin.plans.update', this.initialPlan))
                : this.form.post(this.route('api.admin.plans.store'));
        },

        save () {
            this.newSaveRequest()
                .then(() => {
                    window.location.href = this.route('admin.plans.index');
                })
                .catch(() => {
                    this.$toasted.global.error('There was an error saving the plan.');
                });
        },

        toggleAllAlerts () {
            this.allAlertsValue = !this.allAlertsValue;

            keys(this.form.features).forEach((feature) => {
                this.form.features[feature].notifyImmediately = this.allAlertsValue;
            });
        }
    }
};
</script>
